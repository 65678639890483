import Vue from 'vue'
import store from './store'

import SocketIO from 'socket.io-client'
import VueEcho from 'vue-echo-laravel'

Vue.use(VueEcho, {
  broadcaster: 'socket.io',
  host: `${process.env.API_BASE_URL}:${process.env.SOCKET_PORT}`,
  client: SocketIO,
  namespace: '',
})

// include vue-custom-element plugin to Vue
import VueCustomElement from 'vue-custom-element'
Vue.use(VueCustomElement)

// import and register your component(s)
import DumboClientChat from './components/DumboClientChat'
DumboClientChat.store = store
Vue.customElement('dumbo-client-chat', DumboClientChat)
