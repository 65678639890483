import httpClient from './httpClient'

const END_POINT = '/support/client/chat/rooms'

const getMessages = (token, params) =>
  httpClient.get(`${END_POINT}/${token}/messages`, params)

const postMessage = (token, params) =>
  httpClient.post(`${END_POINT}/${token}/messages`, params)

export { getMessages, postMessage }
