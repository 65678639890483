import Vue from 'vue'
import Vuex from 'vuex'

import * as rooms from '@/store/modules/rooms.js'
import * as messages from '@/store/modules/messages.js'
import * as errors from '@/store/modules/errors.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    rooms,
    messages,
    errors,
  },
})
