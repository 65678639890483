import H from '@/utils/Helpers'

export default class User {
  constructor(obj = {}) {
    if (obj instanceof User) {
      Object.keys(obj).forEach((element) => {
        this[element] = obj[element]
      })
    } else {
      this.id = obj && !H.isEmpty(obj) && obj.id ? obj.id : null
      this.firstName =
        obj && !H.isEmpty(obj) && obj.firstName ? obj.firstName : ''
      this.lastName = obj && !H.isEmpty(obj) && obj.lastName ? obj.lastName : ''
      this.photo =
        obj && !H.isEmpty(obj) && obj.photo
          ? obj.photo
          : 'https://dumbo-client-chat.s3.us-east-2.amazonaws.com/img/user-round-circle.svg'
    }
  }

  get fullName() {
    return this.firstName.concat(' ', this.lastName)
  }
}
