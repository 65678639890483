<template>
  <div class="end-modal">
    <div class="end-modal__background" @click="$emit('close')"></div>
    <div class="end-modal__box">
      <slot @close="$emit('close')" @accept="$emit('accept')"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalContainer',
}
</script>

<style lang="scss" scoped>
.end-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.35);
  }

  &__box {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    background-color: #fff;
    padding: 10px;
    width: 80%;
    margin: 0 auto;
    border-radius: 4px;
  }
}
</style>
