import H from '@/utils/Helpers'
import User from '@/models/User'

export default class Message {
  constructor(obj = {}) {
    if (obj instanceof Message) {
      Object.keys(obj).forEach((element) => {
        this[element] = obj[element]
      })
    } else {
      this.id = obj && !H.isEmpty(obj) && obj.id ? obj.id : null
      this.message = obj && !H.isEmpty(obj) && obj.message ? obj.message : ''
      this.createdAt =
        obj && !H.isEmpty(obj) && obj.createdAt ? obj.createdAt : ''
      this.user =
        obj && !H.isEmpty(obj) && obj.user && !H.isEmpty(obj, 'user')
          ? new User(obj.user)
          : null
    }
  }
}
