export default class Helper {
  static isEmpty(obj, key = '') {
    if (typeof key === 'string' && key.length && Object.keys(obj).length) {
      return !Object.prototype.hasOwnProperty.call(obj, key)
    }
    return Object.keys(obj).length === 0 && obj.constructor === Object
  }
  static dateToUTC(date) {
    const reggie = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/
    const dateArray = reggie.exec(date)
    return new Date(
      Date.UTC(
        +dateArray[1],
        +dateArray[2] - 1, // Careful, month starts at 0!
        +dateArray[3],
        +dateArray[4],
        +dateArray[5],
        +dateArray[6]
      )
    ).toUTCString()
  }
}
