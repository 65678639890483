import { createChatRoom, closeChatRoom } from '@/api/rooms.api.js'

export const namespaced = true

export const state = {
  token: '',
}

export const mutations = {
  SET_TOKEN(state, token) {
    state.token = token
  },
}

export const actions = {
  async createChatRoom({ commit }) {
    try {
      const { data } = await createChatRoom()
      commit('SET_TOKEN', data.token)
      localStorage.setItem('dumbo-client-chat.token', data.token)
    } catch (e) {
      throw e
    }
  },
  async closeChatRoom({ commit, state }) {
    try {
      await closeChatRoom(state.token)
    } catch (e) {
      throw e
    }
  },
  async setToken({ commit }, token) {
    commit('SET_TOKEN', token)
  },
}

export const getters = {
  token: (state) => state.token,
}
