<template>
  <div
    class="dumbo-chat__container--messages-item"
    :class="{ reversed: !message.user }"
  >
    <div class="image">
      <img
        :src="
          message.user
            ? message.user.photo
            : 'https://dumbo-client-chat.s3.us-east-2.amazonaws.com/img/user-round-circle.svg'
        "
        :alt="message.user ? message.user.fullName : ''"
      />
    </div>
    <div class="content">
      <span class="name">
        {{ message.user ? `${message.user.fullName}` : 'Me' }}
      </span>
      <span class="text">
        {{ message.message }}
      </span>
      <span class="time">
        {{ formatDate(message.createdAt) }}
      </span>
    </div>
  </div>
</template>

<script>
import Message from '@/models/Message'

export default {
  name: 'MessageItem',
  props: {
    message: {
      type: Message,
      default: () => new Message(),
    },
  },
  methods: {
    formatDate(date) {
      const d = new Date(date)
      const dtf = new Intl.DateTimeFormat('en', {
        hour: '2-digit',
        minute: '2-digit',
      })
      return dtf.format(d)
    },
  },
}
</script>

<style lang="scss" scoped>
.dumbo-chat__container--messages {
  &-item {
    display: flex;
    width: 100%;
    margin-bottom: 10px;

    &.reversed {
      flex-direction: row-reverse;

      .image {
        margin-right: 0;
        margin-left: 10px;
      }

      .content {
        margin-right: 0;
        background-color: #d2edff;

        .time {
          color: #3575c8;
        }

        &::before {
          border-right: 5px solid transparent;
          border-left: 8px solid #d2edff;
          left: auto;
          right: -13px;
        }
      }
    }

    .image {
      margin-right: 10px;
      width: 24px;
      min-width: 24px;
      height: 24px;
      min-height: 24px;
      border-radius: 50%;
      background-size: cover;
      background-position: center center;
    }

    .content {
      position: relative;
      box-sizing: border-box;
      background-color: #f0f0f0;
      padding: 10px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      max-width: 245px;
      width: 100%;

      &::before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-right: 8px solid #f0f0f0;
        left: -13px;
        top: 7px;
      }

      .name {
        font-size: 12px;
        color: #353535;
        font-weight: bold;
      }

      .text {
        font-size: 12px;
        color: rgb(77, 77, 77);
        font-weight: normal;
        white-space: pre-line;
      }

      .time {
        font-size: 10px;
        color: rgb(114, 114, 114);
        text-align: right;
      }
    }
  }
}
</style>
