import { getMessages, postMessage } from '@/api/messages.api.js'
import Message from '@/models/Message'

export const namespaced = true

export const actions = {
  async getMessages({ commit, rootGetters }, params) {
    try {
      const { data, headers } = await getMessages(
        rootGetters['rooms/token'],
        params
      )
      return {
        meta: {
          itemsCount: headers['x-items-count'],
        },
        data: data.map((el) => new Message(el)),
      }
    } catch (e) {
      throw e
    }
  },
  async postMessage({ commit, rootGetters }, params = {}) {
    try {
      const { data, headers } = await postMessage(
        rootGetters['rooms/token'],
        params
      )
      return {
        meta: {
          itemsCount: headers['x-items-count'],
        },
        data: new Message(data),
      }
    } catch (e) {
      throw e
    }
  },
}
