export const namespaced = true

export const state = {
  showError: false,
  error: null,
}

export const mutations = {
  SET_SHOW_ERROR(state, status) {
    state.showError = status
  },
  SET_ERROR(state, error) {
    state.error = error
  },
}

export const actions = {
  setError({ commit }, payload) {
    commit('SET_SHOW_ERROR', true)
    commit('SET_ERROR', payload)
  },
  clearError({ commit }) {
    commit('SET_SHOW_ERROR', false)
    commit('SET_ERROR', null)
  },
}

export const getters = {
  showError: (state) => state.showError,
  error: (state) => state.error,
}
