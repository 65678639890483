import axios from 'axios'
import store from '@/store'

const httpClient = axios.create({
  baseURL: process.env.API_BASE_URL,
  timeout: 1000, // indicates, 1000ms ie. 1 second
  headers: {
    'Content-Type': 'application/json',
  },
})

// interceptor to catch errors
const errorInterceptor = (error) => {
  // check if it's a server error
  if (!error.response) {
    store.dispatch('errors/setError', {
      type: 'Error',
      text: 'Oops, something gone wrong...',
    })
    return Promise.reject(error)
  }

  // all the other error responses
  switch (error.response.status) {
    case 400:
    case 406:
      store.dispatch('errors/setError', {
        type: 'Error',
        text: error.message,
      })
      break

    case 404:
      store.dispatch('errors/setError', {
        type: 'Error',
        text: 'Session closed',
      })
      break

    default:
      store.dispatch('errors/setError', {
        type: 'Error',
        text: 'Server error',
      })
  }
  return Promise.reject(error)
}

// Interceptor for responses
const responseInterceptor = (response) => response

httpClient.interceptors.response.use(responseInterceptor, errorInterceptor)

export default httpClient
