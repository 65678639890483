<template>
  <div
    ref="draggableContainer"
    class="dumbo-chat__wrapper"
    :style="'bottom: 0; right:' + (isMobile ? '0px' : '20px;')"
  >
    <transition name="slide-up-delayed">
      <div
        v-if="!showChat"
        class="dumbo-chat__label"
        @click="toggleChatWindow()"
      >
        <span class="icon">
          <img
            src="https://dumbo-client-chat.s3.us-east-2.amazonaws.com/img/chat.svg"
            alt=""
          />
        </span>
        <span class="bold">Online</span>
        <span class="normal"> - Chat With Us</span>
        <span v-if="unreadCount" class="unread">{{ unreadCount }}</span>
      </div>
    </transition>
    <transition name="slide-up">
      <div v-show="showChat" class="dumbo-chat__container">
        <div class="dumbo-chat__container--header" @mousedown="dragMouseDown">
          <span class="title">
            Support
          </span>
          <span class="icon-underline" @click="toggleChatWindow()"></span>
        </div>

        <div class="dumbo-chat__container--messages-header">
          <div class="text">
            <span class="title">Live Support</span>
            <span class="subtitle">Ask us anything</span>
          </div>
          <transition name="fade">
            <button v-if="token" @click="showCloseModal = true">
              End chat
            </button>
          </transition>
        </div>

        <div ref="chatWindow" class="dumbo-chat__container--messages">
          <MessageItem
            v-for="(message, index) in messages"
            :key="index"
            :message="message"
          />
        </div>

        <div class="dumbo-chat__container--bottom">
          <textarea
            v-model="message"
            class="textarea"
            placeholder="Type your message here"
            @keydown.enter="onEnter"
          ></textarea>
          <button
            :disabled="!message.length"
            title="Send message"
            @click="sendMessage"
          >
            <img
              src="//dumbo-client-chat.s3.us-east-2.amazonaws.com/img/telegram.svg"
              alt=""
            />
          </button>
        </div>
        <div class="dumbo-chat__container--help">
          <span>
            To send the message press CTRL + ENTER or click the button
          </span>
        </div>
        <transition name="fade">
          <ModalContainer
            v-if="showNotificationModal"
            class="end-modal"
            @close="
              showNotificationModal = false
              showChat = false
            "
          >
            <p>Your chat session was closed by support.</p>
            <div class="buttons">
              <button
                class="cancel"
                @click="
                  showNotificationModal = false
                  showChat = false
                "
              >
                Close
              </button>
            </div>
          </ModalContainer>
        </transition>
        <ErrorSnackbar />
        <transition name="fade">
          <ModalContainer v-if="showCloseModal" class="end-modal">
            <p>Are you sure you want to leave chat session?</p>
            <div class="buttons">
              <button class="accept" @click="endSession()">
                Leave
              </button>
              <button class="cancel" @click="showCloseModal = false">
                Cancel
              </button>
            </div>
          </ModalContainer>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Vue from 'vue'
import Message from '@/models/Message'
import MessageItem from '@/components/message/Message'
import ErrorSnackbar from '@/components/error/ErrorSnackbar'
import ModalContainer from '@/components/modals/ModalContainer'
import H from '@/utils/Helpers'

export default {
  name: 'DumboClientChat',
  components: {
    MessageItem,
    ErrorSnackbar,
    ModalContainer,
  },
  data: () => ({
    message: '',
    messages: [],
    showChat: false,
    showCloseModal: false,
    positions: {
      clientX: undefined,
      clientY: undefined,
      movementX: 0,
      movementY: 0,
    },
    page: 1,
    isNextPage: true,
    unreadCount: 0,
    showNotificationModal: false,
  }),
  computed: {
    ...mapGetters({
      token: 'rooms/token',
    }),
    isMobile() {
      return window.innerWidth < 425
    },
  },
  watch: {
    showChat(val) {
      let element = document.querySelector('.dumbo-chat__container--messages')
      if (val) {
        this.unreadCount = 0
        this.initApp()
        element.onscroll = () => {
          this.onScroll()
        }
      } else {
        element.onscroll = null
      }
    },
  },
  methods: {
    ...mapActions({
      fetchMessages: 'messages/getMessages',
      postMessage: 'messages/postMessage',
      createSession: 'rooms/createChatRoom',
      closeChatRoom: 'rooms/closeChatRoom',
      setToken: 'rooms/setToken',
    }),
    scrollToBottom() {
      let element = document.querySelector('.dumbo-chat__container--messages')
      Vue.nextTick(() => {
        element.scrollTop = element.scrollHeight
      })
    },
    defineParams(page) {
      return {
        page: this.page,
        perPage: process.env.MESSAGES_PER_PAGE,
        sort: '-createdAt',
      }
    },
    async initApp() {
      const token = localStorage.getItem('dumbo-client-chat.token')
      if (token) {
        this.setToken(token)
        await this.initSockets()
        if (!this.messages.length) {
          await this.getMessages()
        }
      }
      this.scrollToBottom()
    },
    initSockets() {
      this.$echo
        .join(`support.chat.${this.token}`)
        .listen('message', (e) => {
          const message = {
            id: e.id,
            message: e.message,
            createdAt: H.dateToUTC(e.created_at),
            user: {
              id: e.user_id || null,
              firstName: e.user_first_name || '',
              lastName: e.user_last_name || '',
              photo: e.user_photo || '',
            },
          }
          if (e.user_id) {
            this.messages.push(new Message(message))
            this.scrollToBottom()
          }
          if (!this.showChat) {
            this.unreadCount++
          }
        })
        .listen('close', (e) => {
          if (this.token) {
            this.sessionClosedBySupport()
          }
        })
    },
    async getMessages() {
      const { data, meta } = await this.fetchMessages(this.defineParams())
      this.messages.unshift(...data)
      this.isNextPage = this.messages.length < meta.itemsCount
      this.page++
    },
    async sendMessage() {
      if (!this.token) {
        await this.createSession()
        await this.initSockets()
      }
      if (this.message) {
        const { data, meta } = await this.postMessage({
          message: this.message,
        })
        this.messages.push(data)
        this.scrollToBottom()
        this.isNextPage = this.messages.length < meta.itemsCount
        this.message = ''
      }
    },
    async endSession() {
      await this.closeChatRoom()
      localStorage.removeItem('dumbo-client-chat.token')
      this.$echo.leave()
      this.setToken('')
      this.showCloseModal = false
      this.showChat = false
      this.messages = []
      this.page = 1
      this.isNextPage = true
    },
    sessionClosedBySupport() {
      this.showNotificationModal = true
      localStorage.removeItem('dumbo-client-chat.token')
      this.$echo.leave()
      this.setToken('')
      this.showCloseModal = false
      this.messages = []
      this.page = 1
      this.isNextPage = true
    },
    toggleChatWindow() {
      this.showChat = !this.showChat
      this.positions = {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
      }
      this.$refs.draggableContainer.style.top = null
      this.$refs.draggableContainer.style.left = null
      this.$refs.draggableContainer.style.bottom = 0
      this.$refs.draggableContainer.style.right = this.isMobile ? 0 : '20px'
    },
    dragMouseDown(event) {
      event.preventDefault()
      // get cursor position
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      document.onmousemove = this.elementDrag
      document.onmouseup = this.closeDragElement
    },
    elementDrag(event) {
      event.preventDefault()
      this.positions.movementX = this.positions.clientX - event.clientX
      this.positions.movementY = this.positions.clientY - event.clientY
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      // change element position
      if (
        this.$refs.draggableContainer.offsetTop - this.positions.movementY >=
          0 &&
        this.$refs.draggableContainer.offsetTop -
          this.positions.movementY +
          this.$refs.chatWindow.clientHeight <
          window.innerHeight
      ) {
        this.$refs.draggableContainer.style.top =
          this.$refs.draggableContainer.offsetTop -
          this.positions.movementY +
          'px'
        this.$refs.draggableContainer.style.bottom = null
      }
      if (
        this.$refs.draggableContainer.offsetLeft - this.positions.movementX >=
          0 &&
        this.$refs.draggableContainer.offsetLeft -
          this.positions.movementX +
          this.$refs.chatWindow.clientWidth <
          window.innerWidth
      ) {
        this.$refs.draggableContainer.style.left =
          this.$refs.draggableContainer.offsetLeft -
          this.positions.movementX +
          'px'
        this.$refs.draggableContainer.style.right = null
      }
    },
    closeDragElement() {
      document.onmouseup = null
      document.onmousemove = null
    },
    async onScroll(event) {
      let previousScroll = this.$refs.chatWindow.scrollHeight
      if (this.$refs.chatWindow.scrollTop === 0 && this.isNextPage) {
        await this.getMessages()
        this.$refs.chatWindow.scrollTop =
          this.$refs.chatWindow.scrollHeight - previousScroll
      }
    },
    onEnter(e) {
      if (e.ctrlKey) {
        this.sendMessage()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease 0s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.slide-up-enter-active,
.slide-up-leave-active {
  transition: opacity 0.5s ease 0s, transform 0.5s ease 0s;
}

.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(100%);
  opacity: 0;
}

.slide-up-delayed-enter-active {
  transition: opacity 0.5s ease 0.5s, transform 0.5s ease 0.5s;
}

.slide-up-delayed-leave-active {
  transition: opacity 0s linear 0s;
  display: none;
}

.slide-up-delayed-enter,
.slide-up-delayed-leave-to {
  opacity: 0;
  transform: translateY(100%);
}

.dumbo-chat {
  &__wrapper {
    position: fixed;
    z-index: 9999;
  }

  &__label {
    font-family: Verdana, Geneva, sans-serif;
    background-color: #3f813a;
    padding: 10px;
    border-radius: 4px 4px 0 0;
    font-weight: normal;
    color: #fff;
    font-size: 12px;
    padding-right: 35px;
    user-select: none;

    &:hover {
      cursor: pointer;
    }

    span {
      &.icon {
        font-size: 13px;
        margin-right: 5px;
        position: relative;
        bottom: -2px;
        img {
          width: 12px;
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(298deg)
            brightness(102%) contrast(102%);
        }
      }

      &.bold {
        font-weight: bold;
      }

      &.normal {
        font-weight: normal;
      }

      &.unread {
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;
        margin: auto;
        display: block;
        width: 18px;
        height: 18px;
        line-height: 18px;
        text-align: center;
        border-radius: 50%;
        background-color: rgb(209, 41, 41);
        color: #fff;
      }
    }
  }

  &__container {
    font-family: Verdana, Geneva, sans-serif;
    width: 290px;
    height: 400px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 3px 2px;
    border-radius: 4px 4px 0 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    @media screen and (max-width: 425px) {
      width: 100vw;
      height: 100vh;
      left: 0;
      top: 0;
      right: 0;
    }

    &--header {
      width: 100%;
      background-color: #3f813a;
      display: flex;
      box-sizing: border-box;

      &:hover {
        cursor: grab;
      }

      .title {
        padding: 5px 10px 5px 36px;
        font-size: 12px;
        color: #fff;
        flex: 1;
        user-select: none;
        text-align: center;
        @media screen and (max-width: 425px) {
          font-size: 14px;
          padding: 10px;
        }
      }

      .icon-underline {
        display: block;
        position: relative;
        width: 26px;
        height: 26px;

        @media screen and (max-width: 425px) {
          width: 36px;
          height: 36px;
        }

        &:hover {
          cursor: pointer;
        }

        &::before {
          content: '';
          position: absolute;
          width: 12px;
          height: 2px;
          border-bottom: 2px solid #fff;
          left: 0;
          right: 0;
          margin: 0 auto;
          bottom: 10px;
        }
      }
    }

    &--messages {
      position: relative;
      flex: 1;
      overflow-y: auto;
      padding: 5px 10px;

      &-header {
        box-sizing: border-box;
        display: flex;
        padding: 10px;
        width: 100%;
        border-bottom: 1px solid #eee;

        .text {
          display: flex;
          flex-direction: column;
          flex: 1;
        }

        .title {
          display: inline-block;
          font-weight: bold;
          color: #555555;
          font-size: 12px;
          margin-bottom: 5px;
        }

        .subtitle {
          display: inline-block;
          font-weight: normal;
          color: #555555;
          font-size: 12px;
        }

        button {
          border: none;
          color: #fff;
          background-color: rgb(209, 41, 41);
          padding: 0 10px;
          border-radius: 4px;
          outline: none;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    &--bottom {
      position: relative;
      width: 100%;
      padding: 10px;
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
      box-sizing: border-box;
      display: flex;

      .textarea {
        box-sizing: border-box;
        min-height: 42px;
        max-height: 64px;
        overflow: hidden;
        overflow-wrap: break-word;
        resize: none;
        width: 100%;
        flex: 1;
        padding-right: 30px;
        outline: none;
        border: none;
        color: #4d4d4d;

        &::placeholder {
          font-family: Verdana, Geneva, sans-serif;
          color: #aaa;
          font-style: italic;
          font-size: 12px;
        }
      }

      button {
        position: absolute;
        width: 37px;
        background-color: transparent;
        color: #c4c4c4;
        border: none;
        right: 10px;
        font-weight: bold;
        outline: none;
        padding: 10px 0px 10px 10px;

        img {
          width: 24px;
          /*filter: invert(78%) sepia(0%) saturate(0%) hue-rotate(159deg)*/
          /*  brightness(104%) contrast(87%);*/
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    &--help {
      font-size: 10px;
      padding: 3px 6px;
      color: #aaaaaa;
    }
  }
}

.end-modal {
  p {
    font-size: 14px;
    text-align: center;
  }
  .buttons {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    button {
      padding: 8px 16px;
      font-size: 14px;
      outline: none;
      &:first-child {
        margin-right: 10px;
      }
      &:hover {
        cursor: pointer;
      }

      &.accept {
        border: none;
        background-color: rgb(240, 240, 240);
        color: rgb(77, 77, 77);
        border-radius: 4px;

        &:hover {
          text-decoration: underline;
        }
      }

      &.cancel {
        border: none;
        background-color: #3f813a;
        color: #fff;
        border-radius: 4px;
      }
    }
  }
}
</style>
