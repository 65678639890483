<template>
  <div class="error-snackbar__container">
    <transition name="fade">
      <div
        v-if="show"
        class="error-snackbar__background"
        @click="show = false"
      ></div>
    </transition>
    <transition name="slide-up">
      <div v-if="show" class="error-snackbar__modal" @click="show = false">
        <p>{{ text }}</p>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ErrorSnackbar',
  props: {
    duration: {
      type: Number,
      default: 3000,
    },
  },
  data() {
    return {
      show: false,
      text: '',
      type: '',
      timer: null,
    }
  },
  computed: {
    ...mapGetters({
      showError: 'errors/showError',
      error: 'errors/error',
    }),
  },
  watch: {
    error(val) {
      if (val) {
        this.showSnackbar(this.error.text, this.error.type)
      }
    },
  },
  methods: {
    ...mapActions({
      clearError: 'errors/clearError',
    }),
    hideSnackbar() {
      this.show = false
      this.timer = null
    },
    showSnackbar(text, type) {
      if (this.timer !== null) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.show = true
      this.text = text
      this.type = type
      this.timer = setTimeout(() => {
        this.clearError()
        this.show = false
        this.text = ''
        this.type = ''
      }, this.duration)
    },
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.35s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: opacity 0.7s ease 0s, transform 0.6s ease 0s;
}

.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(100%);
}

.error-snackbar {
  &__container {
    &.hidden {
      height: 0;
    }

    p {
      color: #000;
    }
  }

  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.35);
  }

  &__modal {
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    background-color: #fff;
    padding: 10px;
    border-top: 3px solid red;

    h4 {
      font-size: 16px;
      margin: 5px 0;
    }

    p {
      margin: 0;
      font-size: 14px;
    }
  }
}
</style>
